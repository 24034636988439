import React from 'react'
import {useParams} from 'react-router-dom'

const Photo = () => {
  const {no} = useParams()
  const style = {
    width: '100vw', height: '100vh',
    backgroundImage: `url(/pix${no}.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50%',
  }

  return (
      <div style={style}/>
  )
}

export default Photo
