import React from 'react'
import RandomFlipCell from './RandomFlipCell'
import titles from './titles'

// Object to Array (key->index)
const o2a = o => {
  const a = []
  Object.keys(o).forEach(it => a[it] = o[it])
  return a
}

// t は曲名を順に並べて, 12 日目に "" を入れた array
const t = o2a(titles).filter(it => !!it)
t.splice(12, 0, '')

// o は曲名の Array (曲がない場合も含む)
const o = o2a(titles)

const titleFileName = (row, col) => {
  const index = o.findIndex(it => it === t[row * 5 + col])
  return `title${index}.jpg`
}

const photoFileName = (row, col) => {
  const index = row * 5 + col
  const j = (index < 12 ? index : index - 1) + 1
  return `pix${j}.jpg`
}

const PhotoTile = () => (
    <div align="center">
      <table>
        <tbody>
        {
          [0, 1, 2, 3, 4].map(row => {
            return (
                <tr key={row}>
                  {
                    [0, 1, 2, 3, 4].map(col => {
                      return (
                          <td key={col}>
                            {
                              row === 2 && col === 2 ?
                                  <div/> :
                                  <RandomFlipCell lyric={titleFileName(row, col)} photo={photoFileName(row, col)}/>
                            }
                          </td>
                      )
                    })
                  }
                </tr>
            )
          })
        }
        </tbody>
      </table>
    </div>
)

export default PhotoTile
