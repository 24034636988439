import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router'

const sec = 1000

const RandomFlipCell = ({photo, lyric}) => {
  const [upside, setUpside] = useState(true)
  const history = useHistory()

  const onClick = () =>
      upside ?
          history.push(`/pix/${photo.slice(3, -4)}`) :
          history.push(`/title/${lyric.slice(5, -4)}`)

  useEffect(() => {
    const interval = Math.random() * 20 * sec
    const timer = setTimeout(() => setUpside(!upside), interval)
    return () => clearTimeout(timer)
  }, [upside])

  return (
      <div align="center" style={{backgroundColor: '#008b8b'}} onClick={onClick}>
        <img src={upside ? photo : lyric} alt={upside ? photo : lyric} style={{height: '20vmin', width: '20vmin'}}/>
      </div>
  )
}

export default RandomFlipCell
