import React from 'react'
import {useParams} from 'react-router-dom'
import titles from './titles'
import './Title.css'

const Title = () => {
  const {no} = useParams()
  const style = {
    width: '100vw', height: '100vh',
    backgroundImage: `url(/title${no}.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '50%',
  }

  return titles[no] ? (
      <div style={style}>
        <div className="title-name">
          {titles[no]}
        </div>
      </div>
  ) : <div></div>
}

export default Title

