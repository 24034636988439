import React, {useState} from 'react'
import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  getDate,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
} from 'date-fns'
import ja from 'date-fns/locale/ja'
import './Calendar.css'
import {useHistory} from 'react-router'
import titles from './titles'

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date('2016/10/30'))
  const [selectedDate, setSelectedDate] = useState(new Date(`2016/10/${new Date().getDate()}`))

  const history = useHistory()

  const nextMonth = () => setCurrentDate(currentDate)
  const prevMonth = () => setCurrentDate(currentDate)
  const onDateClick = day => {
    setSelectedDate(day)
    const date = day.getDate()
    if (titles[date]) history.push(`/title/${date}`)
  }

  const header = () => {
    const dateFormat = 'MMMM yyyy'

    return (
        <div className="header row flex-middle">
          <div className="column col-start">
            <div className="icon" onClick={prevMonth}>chevron_left</div>
          </div>
          <div className="column col-center">
            <span>{format(currentDate, dateFormat, {locale: ja})}</span>
          </div>
          <div className="col-end">
            <div className="icon" onClick={nextMonth}>chevron_right</div>
          </div>
        </div>
    )
  }

  const daysOfWeek = () => {
    const dateFormat = 'E'
    const days = []
    let startDate = startOfWeek(currentDate)

    for (let i = 0; i < 7; i++) {
      days.push(
          <div className="column col-center day-of-week" key={i}>
            {format(addDays(startDate, i), dateFormat, {locale: ja})}
          </div>,
      )
    }

    return <div className="days row">{days}</div>
  }

  const cells = () => {
    const monthStart = startOfMonth(currentDate)
    const monthEnd = endOfMonth(monthStart)
    const startDate = startOfWeek(monthStart)
    const endDate = endOfWeek(monthEnd)
    const dateFormat = 'd'
    const rows = []
    let days = []
    let day = startDate
    let formattedDate = ''

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat)
        const cloneDay = day
        days.push(
            <div
                className={`column cell ${!isSameMonth(day, monthStart)
                    ? 'disabled' : isSameDay(day, selectedDate)
                        ? 'selected' : ''}`}
                key={day}
                onClick={() => onDateClick(cloneDay)}
            >
              <span className="number">{formattedDate}</span>
              <span className="bg">{formattedDate}</span>
              {
                isSameMonth(day, monthStart) ?
                    <div className="title">{titles[getDate(day)] || ''}</div> :
                    <div/>
              }
            </div>,
        )
        day = addDays(day, 1)
      }
      rows.push(
          <div className="row" key={day}> {days} </div>,
      )
      days = []
    }

    return <div className="body">{rows}</div>
  }

  return (
      <div className="calendar">
        <div>{header()}</div>
        <div>{daysOfWeek()}</div>
        <div>{cells()}</div>
      </div>
  )
}

export default Calendar
