import './App.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Calendar from './Calendar'
import Home from './Home'
import Header from './Header'
import Title from './Title'
import PhotoTile from './PhotoTile'
import Photo from './Photo'
import DiaryIndex from './DiaryIndex'

function App() {
  return (
      <Router>
        <div className="App">
          <Header/>
          <Switch>
            <Route path="/photos"><PhotoTile/></Route>
            <Route path="/calendar"><Calendar/></Route>
            <Route path="/title/:no"><Title/></Route>
            <Route path="/pix/:no"><Photo/></Route>
            <Route path="/diary"><DiaryIndex/></Route>
            <Route path="/" exact><Home/></Route>
          </Switch>
          <footer className="App-footer">
            built by <a href="https://www.metabolics.co.jp/" rel="noreferrer" target="_blank">Metabolics, Ltd.</a> 2021
          </footer>
        </div>
      </Router>
  )
}

export default App
