const titles = {
  1: '世界中の兵士が女性だったら',
  3: '説明',
  4: '繁殖と交尾のため',
  5: 'ぼくのすきなかたち',
  6: 'きっとうまくいくさ',
  7: 'かくもの／かかれるもの',
  8: 'いったことはないけど',
  10: '自分よりも心配',
  11: '気づいて思い出して',
  12: 'あのひと',
  13: 'はだか',
  14: 'いる場合／いない場合',
  17: '名前はまだない',
  18: 'やかん',
  19: 'どちらかといえば',
  20: 'このみち',
  21: 'きもち',
  //22: "高円寺彦六",
  24: '顔に顔',
  25: 'こんな日もわたしの恋人なら',
  26: 'ブルーカラー',
  27: 'ティラノ',
  28: 'GoGoGo／NoNoNo',
  29: 'ポップミュージック',
  30: 'あるじゃないか',
}

export default titles
