import React from 'react'
import diary from './diary'
import './DiaryIndex.css'

const header = () => {
  return (
      <div>
        <h1 style={{textAlign: 'center'}}>「名前はまだない」日記</h1>
        <h2 style={{textAlign: 'right', margin: '10px'}}>佐藤幸雄</h2>
      </div>
  )
}

const contents = () => {
  return (
      <div>
        {
          Object.entries(diary).map(([k, v]) => (
              <div className='diaryIndex'>
                <div className='dateBox'>{k}</div>
                <div className='textBox'>{v}</div>
              </div>
          ))
        }
      </div>
  )
}

const DiaryIndex = () => {
  return (
      <div>
        <div>{header()}</div>
        <div>{contents()}</div>
      </div>
  )
}

export default DiaryIndex
