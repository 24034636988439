import cover from './cover.png'
import './Home.css'

const Home = () => (
    <div className="Home">
      <div className="Home-header">
        <img src={cover} className="Home-logo" alt="cover"/>
        <p>
          このサイトは少しずつ変わっていきます
        </p>
        <a
            className="Home-link"
            href="https://watashitachi5.wordpress.com/"
            rel="noreferrer" target="_blank"
        >
          佐藤幸雄とわたしたち
        </a>
      </div>
    </div>
)

export default Home
