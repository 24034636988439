import {Link} from 'react-router-dom'

const diary = {
  "イントロ": <div>
    <p>どうして音楽が聴きたいだけなのに、その場所で酒やソフトドリンクを飲まなくちゃいけないんだろう、という問いかけをしてみる。それはまぁそうやって音楽の場を成り立たせる方法が長い時間をかけて発明されてきたからなのだと答えることはできるだろう。</p>
    <p>ライブハウスのスケジュールは遅くとも大体２、３か月先のことを決め込みながらスケジュールが埋まってゆくのが通例であるので、２０１６年６月の末に父を看取り、送り、翌月も遺された住居、品物、事務処理などに追われているうち、気がつけば１０月がぽっかりと空いた。さてどうしようかな？と考えた。</p>
    <p>一連の経験から頭がやたらぐるぐると働き、７月８月の自分が今まで迎えたことのない位相にさしかかってきたのは自分でも気づいていたので、切実だった。</p>
    <p>別に何処かに出演させてもらうのではなく、場所を探し出して、其処でやれば良いんだなと気付く。そしてその上で、</p>
  </div>,
  "2016-08-27": <div>
    <p>前野健太バンド（POP鈴木ds）＠月見ル君想フ 聴いている途中に１か月毎日演るというやり方を思いつく。と、日記にある。</p>
    <p>それから場所を探す。山田正樹と会って話す。西荻窪のホームから見えるレンタルスペースが気になる。ギャラリーピュア。駅前の写真屋のあるビルの４階。現場に行ってポスターに有ったQRコードから、レンタルスペースを紹介するサイトがあることを知り、料金とか借り方とかの予備知識を得る。毎回、設営から撤収までを１時間で終わらせれば、１か月でもこれくらいの場所代で済むという青写真を描く。須川善行と会って話す。現在使われていない解体前のアパートなどについ目が行く。テレグラフレコードがあった白夜荘もそんな建物のひとつだったので（ニヒル牛と同じ番地）大家ってどなた？って地引雄一に尋ねる。ビリヤード場を眺める。ギャラリーを見て回る。（最初は自分の住む西荻の街でなんとかならないかと考えていた）マドギャラリー、アミーハウス、解体が決まっているビルの博多ラーメン屋にこのビルどうなるの？と、訊く。</p>
  </div>,
  "2016-09-20": <div>
    <p>円盤にて「フジヤマ渡辺正に聞く」というトークイベントを行う。フジヤマか？いや、毎日だとそこでは無理だ。開いてないかもしれない、は悪い冗談としても、夕方から屋外では。畳敷きの西荻銀盛会館でもない。</p>
    <p>（ところで西荻は静かな街だ。夕方、少し盛り場を離れると、テレビの音が漏れてくることも無く、昼下がりにピアノの音が聞こえることもない）</p>
    <p>一ヶ月が経った。</p>
  </div>,
  "2016-09-25": <div>
    <p>山田正樹より毎日演ることを強く勧められる。そうだ、今までの自分の音源の整理とかではなく、やはりむしろそちらをやらねば。漠然とした運営の仕方のイメージは出来ていた。スタッフは自分だけ、入場料など取らない。その代わり前日の演奏から一曲抜き出してCDｰRを作り、それをお土産に買っていただく。</p>
    <p>最初に目をつけたギャラリーピュアはスペイシーというレンタルスペースを紹介する会社が（そういうものがあるのだと初めて知った）管理しているとわかり問い合わせる。CDｰRWを発注（CD－RWは消せるから良い）。</p>
  </div>,
  "2016-09-26": <div>
    <p>しかし、ギャラリーピュアは音楽には貸さないと断わられる</p>
  </div>,
  "2016-09-27": <div>
    <p>それも無理からぬことと思い、スペイシーで音楽が可能なスペースを検索しながら思案する。お、ここなら良いかもしれない場所を見つける。まだ貸し会議室に転用したばかりで夜のその時間は空いているぞ。</p>
  </div>,
  "2016-09-28": <div>
    <p>神田小川町の地下にそのレンタルスペース「NEXT SPACE 御茶ノ水」を見にゆく。悪くない。顔のワイシャツのビルのすぐ近く。一階がタイ焼き屋の小さなビルの地下の一室。６人分の椅子。音楽可。</p>
  </div>,
  "2016-09-29": <div>
    <p>決めた。夜の７時より１時間、基本毎日、２３日分の予約をする。HPに告知文を書き、SNSで公開、一斉メールで知人友人に伝える。以下、その告知文。</p>
    <blockquote style={{backgroundColor: 'whitesmoke', fontSize: "small"}}>
      <p>１０月</p>
      <p>毎日演ってみることにしました</p>
      <p>１日～２９日迄（日曜と15と22の土曜を除く）</p>
      <p>正確に言うと毎日ではないですがね</p>
      <p>千代田線「御茶ノ水駅」都営新宿線「小川町駅」から最寄りの貸しスペースが会場です</p>
      <p>（B7出口を出て、小川町交差点方面、交差点の角のビルの地下。1Fは、たい焼き屋（神田達磨）です</p>
      <p>夜の7時から１時間だけ</p>
      <p>自分一人で管理を行えるように入場料とか取りません</p>
      <p>毎日、前日の演奏が一曲だけ入ったソフトを作って売りますので</p>
      <p>良かれと思われたらそれを買って助けてください</p>
      <br/>
      <p>いろんな街に出向いて旅を続けて歌い続けるのも大変なことだと想像しますが</p>
      <p>同じ街の同じ場所で演り続けることも</p>
      <p>ぼくの演りかただと、結構過酷なことになるのではないかと</p>
      <p>想像してみています</p>
      <br/>
      <p>娯楽としては成立しないかもしれません</p>
      <p>狭い場所ですから、居心地は悪いかもしれません</p>
      <p>でも</p>
      <p>覗きに来てみていただけるとありがたいです</p>
      <p>「公開練習」を始めた数年前とは違った意味での</p>
      <p>何かの「生成の現場」に立ち会っていただけるように思います</p>
    </blockquote>
    <p>大谷氏より「す、すごい」。石川浩司の「兄貴すごい」には笑った</p>
    <p>初日用のお土産ディスクを作成を試みる。自分のバンドの先日のライブから一曲選んで、前後を切り取り、焼こうとするがうまくゆかない。（それまでやったことがないんだもの）山田正樹にSOS。</p>
  </div>,
  "2016-09-30": <div>
    <p>お土産用のCD-RWを入れる紙袋探し、スタンプ台など材料購入。（スタンプ印は円盤で買ってあった）「世界中の兵士が女性だったら」を焼くことになんとか成功。山田正樹がチラシを作ってくれている。感謝。</p>
    <img src="/201610.png" alt="チラシ" width="50%"></img>
  </div>,
  "2016-10-01": <div>
    <p>場所に着いたら、会議室使用中。１９時に入り会議机をどけて、立ち位置を決める。エレキギターに、電池駆動のとても小さなギターアンプ（６cm×１３cm×１３cm）生声。正直、姿勢が定まらない。</p>
    <p>経緯を歌おうとすることから開始。ノートや書いたものを床に散らかし新しい歌詞を歌にしようとすることと、持ち歌(?!)を丁寧に歌おうとすることを交互に連れてゆく。</p>
    <p>客席、須川善行、山田正樹、白江亜古。</p>
    <h4>基本毎日vol.１ 曲目など</h4>
    <ol>
      <li>これまでの経緯と今回の企ての動機についての歌 を歌おうとしてみる</li>
      <li>肩を抱いて…から始まる、この朝生まれた歌の断片</li>
      <li>「<Link to="/title/3">説明</Link>」</li>
      <li>「<Link to="/title/10">自分よりも心配</Link>」</li>
      <li>「<Link to="/title/26">ブルーカラー</Link>」</li>
      <li>毎日やること…から始まる歌の断片</li>
      <li>「<Link to="/title/14">いる場合／いない場合</Link>」</li>
      <li>「<Link to="/title/18">やかん</Link>」</li>
      <li>原発はゆっくりと無くなってゆくだろう…から始まる意見めいたものを歌にするためには工夫がまだまだ必要</li>
      <li>「<Link to="/title/8">いったことはないけど</Link>」</li>
      <li>「トラウタ」</li>
      <li>「さよなら～また会ったね」</li>
    </ol>
    <p>終了して元に戻して施錠。須川さんに、ここは空気が悪いので喉を痛めないように気をつけてと指摘される。</p>
  </div>,
  "2016-10-02": <div>
    <p>前日の演奏から「説明」を抜き出し焼き翌日用のお土産に。書きためた曲の点検。</p>
  </div>,
  "2016-10-03": <div>
    <p>小雨、今日はディレイとオーバードライブをつなぐ。歌詞を記した紙の束を持って臨む</p>
    <p>録音物を翌日のために作る。という決め事が、補助線のように作用し、演り散らかしに終われなくなるのがわかる。「繁殖と交尾のため」を二回仕切り直す。一方、このままいろんな曲の披露で終わって良いのか。とも考える。</p>
    <p>客席、山田正樹、白江亜古。</p>
    <h4>基本毎日vol.２ 曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>その時のわたし・あの頃のわたしたち…で始まる歌</li>
      <li>あなたのしなない世界…で始まる断片の連続</li>
      <li>「<Link to="/title/28">GoGoGo／NoNoNo</Link>」</li>
      <li>「耳をすまして」</li>
      <li>「声（詩：藤富保男）」</li>
      <li>新しい橋が架かる…で始まる歌</li>
      <li>「<Link to="/title/4">繁殖と交尾のため？</Link>」</li>
      <li>「スイッチⅢ(3)」</li>
      <li>「ほどけている」</li>
      <li>「<Link to="/title/21">きもち</Link>」</li>
      <li>「うそのば」</li>
      <li>「スターマン（D・ボウイの歌を日本語にして）</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>最後の「スターマン」、初めて聴き手に身体が向き直った。山田と亜古にこの日の昼間に訳した歌を聴いてもらいたい、という気持ちの成せる業だった。と思った。自分の「姿勢」「態度」が変わった瞬間。</p>
    <p>山田、亜古と食事。ノンアルコールビール（飲んじゃうとこの後の作業ができないため）。</p>
    <p>帰宅して本日の録音を聞き返し「繁殖と交尾のため？」を抜き出し焼き翌日用のお土産に。</p>
  </div>,
  "2016-10-04": <div>
    <p>三歩目の出し方、困っていたのは確か。「ラブソング」縛りで考えてみると、ディレイでループを作って別のアンプで出すとか考えたくなる。サンプルアンドホールドのランダムフィルターを数曲で使う（えーっとマイクロギターアンプに対してエフェクターを使うようになった。ということです）。前々日まではギターをアンプに直結していただけ。</p>
    <p>すきすきの頃の曲も丁寧に扱えた</p>
    <p>客席、白江亜古、山田正樹、POP鈴木、たばやまたばお（という方だとは後からわかる）。</p>
    <h4>基本毎日vol.３ 曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>あなたの腕に抱かれてると思ったら、抱いてるのは自分だった…で始まる歌</li>
      <li>「<Link to="/title/20">このみち</Link>」</li>
      <li>「<Link to="/title/13">はだか</Link>」</li>
      <li>「<Link to="/title/30">あるじゃないか</Link>」</li>
      <li>「<Link to="/title/17">名前はまだない</Link>」</li>
      <li>「スイッチ」</li>
      <li>「<Link to="/title/5">ぼくのすきなかたち</Link>」</li>
      <li>「センチメンタル（岩崎宏美：阿久悠／筒美京平）」</li>
      <li>「私は何人か（わたしはなんにんか）」</li>
      <li>「天国」</li>
      <li>「困らせていいよ（ビーチボーイズのプリーズレットミーワンダーを日本語にして）</li>
      <li>「機械のせいにしない」</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>山田、亜古、POPと食事。ひとりだけノンアルコール。朝、労働して、昼から練習、本番。＝実業団音楽家。という冗談を話す。</p>
    <p>帰宅して「ぼくのすきなかたち」を翌日のお土産に。</p>
  </div>,
  "2016-10-05": <div>
    <p>開始時は聴き手が不在。録音をするという前提のおかげで集中して歌い始める。聴き手の居ない所での即興「３。」は難しい。「４。」の途中でたばやまたばお来場。山田正樹も程なく。</p>
    <p>自分の声がよく出ている。裏声が使える。小さなアンプの歪むいい音。拍手が起きる。</p>
    <h4>基本毎日vol.４　曲目など</h4>
    開錠して開場して会場作り。
    <ol>
      <li>「<Link to="/title/6">きっとうまくゆくさ</Link>」</li>
      <li>「<Link to="/title/29">ポップミュージック</Link>」</li>
      <li>ひとの話が聞き取れなくなっても頭の中の歌はいつでも呼び出せる…などと歌う歌</li>
      <li>「しあわせになろう」</li>
      <li>「<Link to="/title/27">ティラノ</Link>」</li>
      <li>「ふにゃふにゃ：原曲Coomyah（ジョナサン・リッチマンやデスモンド・デッカーのカバーで知られる曲に日本語詞をつけて）」</li>
      <li>「ヤイ・ヤイ・オウ・オウ・イエ・イエ・ウエスタン・ハピネス・イッツ・オールライト（つボイノリオのカバー）」</li>
      <li>「佐藤幸雄のうた」（facebookにサトウユキオは800人以上いる…とか）</li>
      <li>「むだ」</li>
      <li>「<Link to="/title/24">顔に顔</Link>」</li>
      <li>「おみやげⅢ(3)」</li>
      <li>「ここにきてはじめて」</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>今日はひとりで食事を済ませ帰宅。「きっとうまくゆくさ」を焼く。</p>
  </div>,
  "2016-10-06": <div>
    <p>開始を待つ方が数名、ビルの外の路上に。聴き手、山田正樹、白江亜古、林原聡太（この日にはまだただの未知の男性）他三名。</p>
    <p>聴き手それぞれにわざと作った空白部分に歌詞を空想してもらう、などの積極的な語り掛け、参加の要請（あしらい？）などを試みる。</p>
    <p>一方で自由部分が自分でも物足りない。技量が伴わないからだろうか。自分で作ったものをどうしてこんな所で間違えるのだ。とは毎日思う。</p>
    <h4>基本毎日vol.５　曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>「一体どれくらいのうた」</li>
      <li>１日１時間、３分の曲を３５年聴き続けると、延25万5500曲にもなることについての歌</li>
      <li>「十万年（タイムスリップ付き）」</li>
      <li>「ぼくはイノブタ」</li>
      <li>「スイッチⅡ(2)」</li>
      <li>「プリティー・プリティー」（石野真子：阿久悠／筒美京平）</li>
      <li>「ミドルエイジクレイジーラブ」（今井次郎）</li>
      <li>「<Link to="/title/7">かくもの／かかれるもの</Link>」</li>
      <li>ともだちがなくなった×うた・ゆめ・いま・これから…の組み合わされた歌</li>
      <li>ぼくたちは勝ったり負けたりする…で始まる歌</li>
      <li>「ジ・エンド・オブ・ザ・ワールド（この世の果てまで：スキータ・デイヴィスに日本語詞を付けて）</li>
      <li>「キャッチフレーズ」～きみに会えて安心して弱くなれた</li>
      <li>「水道管」</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>山田と亜古と食事して帰宅。「かくもの／かかれるもの」を焼く。</p>
  </div>,
  "2016-10-07": <div>
    <p>聴き手、白江亜古。途中から、たばやまたばお。</p>
    <p>ギターのゆっくりとした長いフレーズをサンプラーに取り込んで呼び出し、合わせて弾くときに生ずるズレを使うことを「９。」で試みる。</p>
    <p>開錠して開場して会場作り。</p>
    <h4>基本毎日vol.６　曲目など</h4>
    <ol>
      <li>「<Link to="/title/25">こんな日もわたしの恋人なら声をあげる</Link>」</li>
      <li>「うちにかぎってそんなことはないはず」（森高千里：森高千里／直枝政広）</li>
      <li>「みんなわたろう」（アニメ『ぶぶチャチャ』エンディングテーマ）</li>
      <li>「わたしたち」</li>
      <li>「ユー・アー・ソー・ビューティフル」（ビリー・プレストン、ジョー・コッカーなどの歌で知られるものを日本語にして）</li>
      <li>「<Link to="/title/3">説明</Link>」</li>
      <li>「毎日が君の誕生日」</li>
      <li>～「片端」</li>
      <li>「くすりがききすぎて」</li>
      <li>「おみやげ」</li>
      <li>「星影のワルツ」（千昌夫：白鳥園枝／遠藤実）</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>帰宅して作業。本日の演奏はカバー曲、すきすきスウィッチ曲、が多く、初日の「いったことはないけど」を翌日のお土産用にする。</p>
  </div>,
  "2016-10-08": <div>
    <p>聴き手、白江亜古。</p>
    <p>場所に着くまで声も出さず音も出さず、ですぐに始めることを繰り返すうち、自分も場所も2､3曲で温められる感じがわかってきた。ロングディレイをフリパトロニクス風に使い最後を終わらせる組み立てにし、そうした。</p>
    <h4>基本毎日vol.７　曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>「はじめて」</li>
      <li>「アンテナ」</li>
      <li>「地球を七回半回れ」（NHKみんなのうた：阪田寛夫／越部信義）</li>
      <li>「顔見知り」</li>
      <li>「もうじきまでもうすぐ」</li>
      <li>「<Link to="/title/10">自分よりも心配</Link>」</li>
      <li>「<Link to="/title/11">気がついて思い出して</Link>」</li>
      <li>「激しい恋」</li>
      <li>「忘れてもいいよ」</li>
      <li>「モンゴル」（ピヴィレヌのカバー）</li>
      <li>「アンテナⅡ(2)」</li>
      <li>「あのひとⅡ(2)」</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>本日までの曲目で重複は2曲だけ。</p>
    <p>帰宅して聴き返し。前日のお土産CD-RW「かくもの／かかれるもの」にたばやまたばおさんがシンセサイザーを乗せたものを作って、送ってくれた。ありがたく驚く。</p>
  </div>,
  "2016-10-09": <div>
    <p>「自分よりも心配」を翌日のお土産用に焼く。</p>
    <p>楽器屋でブラックスターのミニアンプ、フライを試す。購入。２台のアンプを使う実験。</p>
  </div>,
  "2016-10-10": <div>
    <p>聴き手、林原聡太。（開場して準備中、先週末のカエターノヴェローゾは行かれました？と尋ねられる。終演後話をして、ミュニオンというファンジンを作っていて、80年頃、絶対零度のマネージャー役を勤めて居た方だとわかった）</p>
    <p>新しいギターアンプを使用。</p>
    <h4>基本毎日vol.８　曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>「家族になろう」</li>
      <li>アオムシがサナギに、サナギがチョウになるのは、進化じゃないよ、変態だよ…で始まる歌</li>
      <li>「逢いたくて逢いたくて」（園まり：岩谷時子／宮川泰）</li>
      <li>「記憶」</li>
      <li>「もれてる」</li>
      <li>「ジェンチ（人々）」（カエターノ・ヴェローゾの歌に原詩に近い日本語詞をつけて）</li>
      <li>「イマジン」（ジョン・レノンの歌に日本語詞をつけて。戦争を選べる国を想像できるかい？）</li>
      <li>～「イマジン２」（イマジンの和音進行と同じにして新しい言葉とメロディで。戦争から帰ってくる、を想像できるかい？）</li>
      <li>～「イマジン３」（同上。まず老人が戦場に行けばいいというので、実際に行かせてみる、というのを想像できるかい？）</li>
      <li>「やめてもいいよ」</li>
      <li>「機械のせいにしない」</li>
      <li>「おみやげⅡ(2)」</li>
      <li>「洪水」（ピーター・ガブリエルのヒア・カム・ザ・フラッドを日本語詞にして）</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>帰宅して聴きかえす。一曲一曲の中に細かいミスがあり録音物としては躊躇する出来、カバーものが大半、以前の録音が存在する曲もなるべく外したかったので、１０・８の録音より「気がついて思い出して」を選んで、焼く。</p>
  </div>,
  "2016-10-11": <div>
    <p>iPadを鳴らす。足の指で弾いて「キュッ、キュッ」って音を鳴らしながらギターを弾き歌う。メロトロンのフルート風の音を伴奏に手の指で弾き歌う。ミニアンプ２台で、ロングディレイ音を交互に鳴らしながら、歌う。</p>
    <p>聴き手、猫道（猫道一家）他二名。</p>
    <h4>基本毎日vol.９　曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>「これだよ」</li>
      <li>「タイミング」</li>
      <li>「きみのことすきじゃないんじゃない」</li>
      <li>「<Link to="/title/18">やかん</Link>」</li>
      <li>「ヤイ・ヤイ・オウ・オウ・イエ・イエ・ウエスタン・ハピネス・イッツ・オール・ライト」（つボイノリオのカバー）</li>
      <li>「<Link to="/title/17">名前はまだない</Link>」</li>
      <li>「はじまりおしまい」</li>
      <li>「きみと一緒に還暦を迎えたい」</li>
      <li>「機械のせいにしない」</li>
      <li>「<Link to="/title/12">あのひと</Link>」</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>どこの部分を修正してゆけば良いか。どこをちゃんとするのか。考える。「あのひと」を翌日のお土産にする。</p>
  </div>,
  "2016-10-12": <div>
    <p>facebookで見つけた記事。敬愛する、一人旅するウクレレ・パンクロッカー「とんちピクルス」こと松浦浩司が「基本毎日」について、こんなことを書いてくれる。前日の猫道（猫道一家）もそうだが、演る側の方々が、より、ざわついていることが伝わってくる。</p>
    <blockquote style={{backgroundColor: 'whitesmoke', fontSize: "small"}}>
      <p>すきすきスウィッチ、わたしたち、の佐藤幸雄さんが、今月毎日同じ場所、同じ時間でのライブをしている。これはミニマリズムであり、コンセプチュアルアートであり何よりパンクだ。</p>
      <p>毎日同じ場所に出かけていき、解錠、設営、開場、演奏、撤収、施錠、を繰り返すこと。１ヶ月というとても長い1曲を演奏していると言うことも出来るだろう。もしくはある日ある時間に演奏された3分の曲にこの企ての全てが透かし絵のように彫りこまれている、とも。</p>
      <p>一度やってみたいツアーに、『毎日公園ツアー』というのがある。福岡市(早良区限定でも良い)の公園を、毎日1時間ライブしながらツアーするのだ。今回佐藤さんの毎日ライブの企てを知った時、正直『あ、先にやられた‼』と悔しく思った(笑)。嬉しい悔しさである。</p>
      <p>とにかく、どこか１日でも良いからこのユーモラスかつ実験的な音楽が生成する場に立ち会いたいと、毎日淡々と更新される演目リストを見ながら、思っている。</p>
    </blockquote>
    <p>毎日を録音（記録）してお土産を作る、という別の視点を設けておいて良かった。それは別の時間軸を設定する補助線になりうる。一方で、その場限りの説得力のある演奏を選ぶというやり方があるということがわかった。両方きちんとできれば良いのだ。が。小さなミスが多い。練習というものと相入れない手法だから、という言い訳めいたことも思う。</p>
    <p>今日もiPadを足指で弾く。聴き手、林原聡太、山田正樹。</p>
    <h4>基本毎日vol.１０　曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>「ものに名前をかく」</li>
      <li>「毎日」</li>
      <li>「<Link to="/title/14">いる場合／いない場合</Link>」</li>
      <li>「あげたい」</li>
      <li>「片手があいてる時にゃ」（守屋浩：詞曲＝平岡精二）</li>
      <li>～「右手がある」→「明日がある」（坂本九：永六輔／中村八大）の替え歌</li>
      <li>「プロフェッショナル童貞」</li>
      <li>「ロックバンドは大変だ、フー」</li>
      <li>「<Link to="/title/20">このみち</Link>」</li>
      <li>「ゆっくりときく」</li>
      <li>「<Link to="/title/1">世界中の兵士が女性だったら</Link>」</li>
      <li>「話しかけたかった」（南野陽子： 戸沢暢美／岸正之）から抜粋</li>
      <li>「乙女のワルツ」（伊藤咲子： 阿久悠 ／三木たかし）</li>
      <li>「<Link to="/title/13">はだか</Link>」</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>帰宅して「はだか」を翌日のお土産に。</p>
  </div>,
  "2016-10-13": <div>
    <p>以前のノートに書き散らしたフレーズ、断片を拾い出してみる作業。</p>
    <p>基本、毎日の演奏の足下には大量の言葉を書きつけた紙が並べてある。</p>
    <p>本日はギターのヘッド側にコンタクトマイクを取り付けて、そちら側の弦の鳴りをもう一台のアンプで鳴らす。</p>
    <p>聴き手、山田正樹、たばやまたばお、他一名。</p>
    <h4>基本毎日vol.１１　曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>めをとじていてっていってるみたいだよね…で、始まる歌</li>
      <li>「<Link to="/title/14">いる場合／いない場合</Link>」</li>
      <li>「五分前にこの歌は出来た」</li>
      <li>「せんでんカー」</li>
      <li>「役にたってる感じ」</li>
      <li>「ひとんちにはひとんちの」</li>
      <li>「<Link to="/title/30">あるじゃないか</Link>」</li>
      <li>「<Link to="/title/19">どちらかといえば</Link>」</li>
      <li>～めをとじていてっていってるわけだよね…で終わる歌</li>
      <li>「問題の一曲」</li>
      <li>海の向こうのショッピングセンターで若者が自爆したってニュースが入ってくる…で始まる歌</li>
      <li>「ふとん」</li>
      <li>「五年」（D・ボウイのファイブ・イヤーズを日本語にして）</li>
      <li>「わかりにくくないように」(B・イーノのアイル・カム・ランニングを日本語にして）</li>
      <li>「逢わずに愛して」（内山田洋とクールファイヴ：川内康範／彩木雅夫 ）</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>「いる場合／いない場合」をお土産に作成。</p>
  </div>,
  "2016-10-14": <div>
    <p>本日はアンプ一台。歪み系のエフェクターばかり並べる。ブラックスターのミニアンプが良く鳴るようになって来た。</p>
    <p>書きつけたけれど、口には出せない歌詞、の、その「口に出せなさ」と向き合う瞬間があった。客層に配慮するということではなく。</p>
    <p>聴き手、山田正樹、他一名。</p>
    <h4>基本毎日vol.１２　曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>例えばあの時ディランを聴いて啓示を受けたような人は今ならディランなんか聴かない。。。。んじゃないかな…から始まる歌</li>
      <li>「今さらいいわけはしない」</li>
      <li>あのときにあれができてたならあれはもう３５歳…で始まる歌</li>
      <li>～「激しい恋」</li>
      <li>「<Link to="/title/10">自分よりも心配</Link>」</li>
      <li>「<Link to="/title/25">こんな日も私の恋人なら声をあげる</Link>」</li>
      <li>「あのこがしてくれた」</li>
      <li>「こまらないで」（ビーチ・ボーイズのドント・ウォーリー・ベイビーを日本語にして）</li>
      <li>「この恋はこれから咲く花」（ジョナサン・リッチマンのマイ・ラブ・イズ・ア・フラワー・ジャスト・ビギニング・トゥ・ブルームを日本語にして）</li>
      <li>「あの夏の感じ」（ジョナサン・リッチマンのザット・サマー・フィーリングスを日本語にして）</li>
      <li>「<Link to="/title/3">説明</Link>」</li>
      <li>「星の数ほど自分が」（突然段ボールのカバー）</li>
      <li>「<Link to="/title/17">名前はまだない</Link>」</li>
      <li>「変な女」（水原弘：浜口庫之助＝詞曲）</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>毎回わりとちゃんとする／ちゃんとなることを成立させているのはどこの部分の話だろうと考える。</p>
    <p>友人に鍼を打ってもらう。</p>
  </div>,
  "2016-10-15~16": <div>
    <p>父の四十九日。納骨。</p>
  </div>,
  "2016-10-17": <div>
    <p>１４日の「名前はまだない」をお土産にし、「きもち」のバックトラックをiPadで打ち込んでみる。</p>
    <p>会議室に着いて、鍵を開けようとすると、ドアに手紙が挟み込んである。土日にライブのため上京していた倉地久美夫からだった。嬉しい気持ち。と同時に、どういうところで何が行われているのかをわざわざぼくがいないのをわかっていて確かめに来たかと思うと、その「何か」に対する貪欲さに感じる畏怖。
      客席、一名。</p>
    <p>まだ出来てない曲を挟んでみながら一曲を長めに作り上げるのはありなのだとわかる。いつのどの部分を正確にするかだ。打ち込み音源も小さなアンプで鳴らしながら。</p>
    <p>大事なのは歌が毎回そこで生産されるような仕組みだ。</p>
    <h4>基本毎日vol.１３　曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>「くちあけ」</li>
      <li>「むだ」</li>
      <li>「おみやげ」</li>
      <li>平気で嘘をついている人とどう付き合えるか…で始まる歌</li>
      <li>「<Link to="/title/30">あるじゃないか</Link>」</li>
      <li>「<Link to="/title/18">やかん</Link>」</li>
      <li>「<Link to="/title/28">GoGoGo／NoNoNo</Link>」</li>
      <li>「会いたかったままだった」</li>
      <li>「<Link to="/title/21">きもち</Link>」</li>
      <li>「<Link to="/title/3">説明</Link>」</li>
      <li>「ここへきてはじめて」</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>帰宅し、「やかん」をお土産にする作業。</p>
    <p>前日、父の法要をしてくださった住職に「そんなことしていたら、家族が大変でしょう」と言われたことを思い出す。倉地久美夫にお礼のメール。「姿勢に身を震わせられました。絶対に真似できない姿です」と返信をもらいこちらが恐縮。</p>
  </div>,
  "2016-10-18": <div>
    <p>聴き手、山田正樹、林原聡太、他三名。</p>
    <p>最初の数曲、場所に対する困惑（緊張）が伝わってくる。しかし当方は何をちゃんとしなくてはいけないかということがわかってきた感じ。毎回出来立てにする部分、小さなミスを減らしてゆくこと、レパートリーは何でも良い。</p>
    <h4>基本毎日vol.１４　曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>「たくさんのはじめなきゃでやっととまる」</li>
      <li>「<Link to="/title/29">ポップミュージック</Link>」</li>
      <li>「スイッチ」</li>
      <li>「<Link to="/title/27">ティラノ</Link>」</li>
      <li>「ふにゃふにゃ」（J・リッチマン等のカバーで知られるCoomyahに日本語詞をつけて）</li>
      <li>「故郷」（由紀さおり：山川啓介／大野雄二）</li>
      <li>「<Link to="/title/7">かくもの／かかれるもの</Link>」</li>
      <li>「しあわせになろう」</li>
      <li>「<Link to="/title/21">きもち</Link>」</li>
      <li>「（よだかの星）」</li>
      <li>「<Link to="/title/3">説明</Link>」</li>
      <li>「<Link to="/title/19">どちらかといえば</Link>」</li>
    </ol>
    <p>終了して元に戻に戻して施錠。ここはほんとに無人の貸し会議室なだけ、荷物を預けたりも出来ないので毎日全て片付け、持ち帰る。</p>
    <p>帰宅して「どちらかといえば」をお土産化。聴いた白江亜古にとてもクレイジーだと言われる。嬉しいこと。</p>
  </div>,
  "2016-10-19": <div>
    <p>聴き手、日高和子（時々自動）、他一名。</p>
    <p>場所に対しての緊張がこちらにも伝わってくる。一曲目で４弦が切れる。張り直す。今日も打ち込み音源iPadを併用。</p>
    <h4>基本毎日vol.１５　曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>「<Link to="/title/29">ポップミュージック</Link>」</li>
      <li>「天国」</li>
      <li>「<Link to="/title/24">顔に顔</Link>」</li>
      <li>「<Link to="/title/30">あるじゃないか</Link>」</li>
      <li>「ミドルエイジクレイジーラブ」（今井次郎のカバー）</li>
      <li>「<Link to="/title/28">GoGoGo／NoNoNo</Link>」</li>
      <li>「二十二世紀音頭」→二十一世紀音頭（佐良直美：山上路夫／いずみたく）の替え歌</li>
      <li>「<Link to="/title/20">このみち</Link>」</li>
      <li>「<Link to="/title/21">きもち</Link>」</li>
      <li>「<Link to="/title/25">こんな日も私の恋人なら</Link>」</li>
      <li>「<Link to="/title/10">自分よりも心配</Link>」</li>
      <li>さよなら…で始まる短い歌</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>「このみち」をお土産化。確かにこの「ずれ」を許すのはクレイジーかもしれんな、と思う。</p>
  </div>,
  "2016-10-20": <div>
    <p>ノートをひっくり返し作っておいて忘れていた曲を思い出し直すような準備を経て。</p>
    <p>客席、山田正樹、他一名。</p>
    <h4>基本毎日vol.１６　曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>「ここへきてはじめて」</li>
      <li>「どんなに遠くにはなれていても」</li>
      <li>「あなたはここにいてほしい」</li>
      <li>「<Link to="/title/24">顔に顔</Link>」</li>
      <li>「むだ」</li>
      <li>「こまらないで」（ビーチボーイズのドント・ウォーリィ・ベイビーを日本語にして）</li>
      <li>「<Link to="/title/28">GoGoGo／NoNoNo</Link>」</li>
      <li>「きみと一緒に還暦を迎えたい」</li>
      <li>「<Link to="/title/21">きもち</Link>」</li>
      <li>「<Link to="/title/7">かくもの／かかれるもの</Link>」</li>
      <li>「<Link to="/title/29">ポップミュージック</Link>」</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>「きもち」ようやくお土産に。録音できてないものを記録するという補助線が効いている。こうして一枚づつ溜まってきている一曲入CD−RWを塊にして作品にすることを思いついたのはこのころか。</p>
  </div>,
  "2016-10-21": <div>
    <p>今日は童謡も。すきすきスウィッチで扱ったが、この日まで触れてこなかったところへ踏み込み。弦を切り、張り、切り、張り、する。</p>
    <p>聴き手、白江亜古、茂木隆行、他二名。</p>
    <h4>基本毎日vol.１７　10月21日　曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>「めをとじていてっていってるみたいだよね」</li>
      <li>「きっとうまくゆく」</li>
      <li>「おみやげ」</li>
      <li>「<Link to="/title/3">説明</Link>」</li>
      <li>「<Link to="/title/24">顔に顔</Link>」</li>
      <li>「<Link to="/title/17">名前はまだない</Link>」</li>
      <li>「<Link to="/title/8">いったことはないけど</Link>」</li>
      <li>「<Link to="/title/14">いる場合／いない場合</Link>」</li>
      <li>「うさぎ」（まどみちお／大中恩）に歌詞を書き足して</li>
      <li>「誰も知らない」（谷川俊太郎／中田喜直）でたらめのことば　ひとりごといって　うしろをみたら　ひとくい土人　わらって立ってた…を歌いたくて</li>
      <li>「機械は皆ンな生きている」（手のひらを太陽に：やなせたかし／いずみたく　の替え歌）</li>
      <li>「ばね」</li>
      <li>「機械のせいにしない」</li>
      <li>「磁石」（「籠の鳥」：千野かおる／鳥取春陽　を元に）</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
  </div>,
  "2016-10-22": <div>
    <p>高円寺、彦六にて歌と演奏。マイクに向かって歌うのは久しぶり。ブラックスターのミニアンプがちょうど良い。自分以外の演奏を聴くのも久しぶり。競演、吉田悠樹＋服部将典。ワンコードの曲、「顔見知り」を共演。</p>
  </div>,
  "2016-10-23": <div>
    <p>「顔に顔」を翌日用のお土産に焼く。林原聡太がブログ”whosellout”を始めた。「基本毎日」について書いてくれる。</p>
  </div>,
  "2016-10-24": <div>
    <p>最終週の始まり。聴き手、山田正樹、地引雄一,</p>
    <p>他一名に加えて、他人の演奏を滅多に聴きにこないと言われる石川浩司があらわれてくれとても嬉しく驚く。（顔のYシャツのビルの近くだし）</p>
    <p>メッセージソングめいたものを歌うとしたら、という視点で選曲。</p>
    <h4>基本毎日vol.１８　曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>「<Link to="/title/27">ティラノ</Link>」</li>
      <li>「<Link to="/title/10">自分よりも心配</Link>」</li>
      <li>「<Link to="/title/3">説明</Link>」</li>
      <li>「おみやげ」</li>
      <li>「<Link to="/title/7">かくもの／かかれるもの</Link>」</li>
      <li>「忘れてもいいよ」</li>
      <li>「<Link to="/title/18">やかん</Link>」</li>
      <li>「<Link to="/title/28">GoGoGo／NoNoNo</Link>」</li>
      <li>海の向こうのショッピングセンターで若者が自爆したというニュースが入ってくる…で始まる歌</li>
      <li>「<Link to="/title/1">世界中の兵士が女性だったら</Link>」</li>
      <li>「わたしたち」</li>
      <li>「<Link to="/title/25">こんな日も私の恋人なら</Link>」</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>参加者と喫茶店で話す。</p>
    <p>帰宅して「こんな日も私の恋人なら」焼く。</p>
  </div>,
  "2016-10-25": <div>
    <p>サンプラーのワンショット（スイッチを押すたびに同じフレーズが鳴る）を試すことにする。まだ演ってない曲があったのにこの頃になって気付いたりする。</p>
    <p>聴き手、林原聡太（今月６回目）織田島高俊（彦六）、他二名。</p>
    <p>拍手をもらえる。「６。」で初の一緒に歌う（コール＆レスポンス）という事態に。「次の」アプローチというのが試せてるように感じる。即興に徹していなくても、その場に立ち興ること</p>
    <h4>基本毎日vol.１９　曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>「ここへきてはじめて」</li>
      <li>「<Link to="/title/27">ティラノ</Link>」</li>
      <li>「<Link to="/title/29">ポップミュージック</Link>」</li>
      <li>もうできることはこづくりのまねごとでしかないかも…で始まる歌</li>
      <li>「程度問題」</li>
      <li>「<Link to="/title/28">GoGoGo／NoNoNo</Link>」</li>
      <li>「<Link to="/title/26">ブルーカラー</Link>」</li>
      <li>「おみやげⅢ(3)」</li>
      <li>「ゆるそう」</li>
      <li>「<Link to="/title/12">あのひと</Link>」</li>
      <li>連絡もしないできたが…で始まる歌（ダニー・ボーイ：ロンドンデリーの歌　で知られるメロディに日本語詞をつけて）</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>「ブルーカラー」を選んで、盤にする。</p>
  </div>,
  "2016-10-26": <div>
    <p>ギターの音色、ちゃんと出すことを狙う。というよりこの日になっても、やりたいと思えることを見つけ出せるありがたさ。黄金コード進行の活用。サンプラーを使ったリフの繰り返しは、必ず途中で弱くなってしまうということ。終了直前、「１４。」のその場で歌詞に曲をつけるアプローチが次の「とばくち」かと思う。</p>
    <p>聴き手、山田正樹、茂木隆行、来住真太、他一名。</p>
    <h4>基本毎日vol.２０　曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>原子力発電所を動かしてる電気はどこから来ているんだろう…で、始まる曲</li>
      <li>～「<Link to="/title/18">やかん</Link>」</li>
      <li>「りんどう峠」（島倉千代子：西條八十／古賀政男）間奏部をジョナサンリッチマンのエジプシャン・レゲエにして</li>
      <li>「ほどけている」</li>
      <li>「スイッチⅡ(2)」</li>
      <li>「<Link to="/title/29">ポップミュージック</Link>」</li>
      <li>「<Link to="/title/27">ティラノ</Link>」</li>
      <li>「もれてる」</li>
      <li>「<Link to="/title/1">世界中の兵士が女性だったら</Link>」</li>
      <li>「ゆるそう」</li>
      <li>「水道管」</li>
      <li>「毎日が君の誕生日」</li>
      <li>～「片端」</li>
      <li>いろんなものがつながろうとするとき…で始まる歌</li>
      <li>「あなたがここにいてほしい」（ピンクフロイドのウイッシュ・ユー・ワー・ヒアを遠藤豆千代の日本語詞で）</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>「ティラノ」を焼く。</p>
  </div>,
  "2016-10-27": <div>
    <p>言葉を大量に書き出して臨む。</p>
    <p>聴き手、POP鈴木、山田正樹、他三名。</p>
    <h4>基本毎日vol.２１　曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>「<Link to="/title/29">ポップミュージック</Link>」</li>
      <li>あなたのための音楽ってなんでしょう…で始まる、断片のつなぎ合わされた長い歌</li>
      <li>ほんとうははなしたくないと、ほんとうにはなしをしたくないはたいへんにていて…と始まる歌</li>
      <li>なんだろうな・に・ね・の…と組み合わさる歌</li>
      <li>どこでも相談がある…で始まる断片の入れ込まれた歌</li>
      <li>明かりがまどにうつる　うつるものをかきとめる…で始まる歌</li>
      <li>「うそのば」</li>
      <li>「<Link to="/title/14">いる場合／いない場合</Link>」</li>
      <li>「<Link to="/title/28">GoGoGo／NoNoNo</Link>」</li>
      <li>「<Link to="/title/30">あるじゃないか</Link>」</li>
      <li>「<Link to="/title/18">やかん</Link>」</li>
      <li>「キャッチフレーズ」</li>
      <li>「<Link to="/title/6">きっとうまくゆくさ</Link>」</li>
      <li>平和がとても続いたのでこういう状態と呼べば良いのかわからなくなりました…で始まる短い歌</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>ギリギリまで追い込む試みと、なんでも「らしく」できてしまうことへの甘えを混同させてはだめだ</p>
    <p>「GoGoGo／NoNoNo」を焼く。</p>
  </div>,
  "2016-10-28": <div>
    <p>前日の反省。自分には自由に完璧を人前で披露できる力量はない。今日と明日をグランドフィナーレと考えるように選曲。</p>
    <p>聴き手、開場前から待っていてくれた二人組（開場と開演が同時、という仕組みには驚いていた）山田正樹、たばやまたばお、他二名の計六名。</p>
    <h4>基本毎日vol.２２　曲目など</h4>
    <p>開錠して開場して会場作り。</p>
    <ol>
      <li>「ここへきてはじめて」</li>
      <li>「<Link to="/title/10">自分よりも心配</Link>」</li>
      <li>「スイッチ」</li>
      <li>「<Link to="/title/3">説明</Link>」</li>
      <li>「<Link to="/title/24">顔に顔</Link>」</li>
      <li>「<Link to="/title/17">名前はまだ無い</Link>」</li>
      <li>「<Link to="/title/18">やかん</Link>」</li>
      <li>「きみと一緒に還暦を迎えたい」</li>
      <li>「<Link to="/title/19">どちらかといえば</Link>」</li>
      <li>「きみのことすきじゃないんじゃない」</li>
      <li>「<Link to="/title/29">ポップミュージック</Link>」</li>
    </ol>
    <p>終了して元に戻して施錠。</p>
    <p>とても丁寧に、を心掛ける（慢心する瞬間にミスが起こる）。音色を含め正確に積んでゆく。一曲一曲終わらせて拍手をいただくサービスはしてもいいし、しなくてもいいし、ある種の集中を途切れさせずにぐいぐいいくのが一番良いようだ。だんだんつくりあげられてきたコトバに少しづつの微調整を毎回毎回つねに怠らない、というのが自分の心構え、で良いのだろう</p>
    <p>いきなり冒頭から全開で持ってゆけたのがよかった。途中、声がヘロヘロになりそうに鳴るが、それでも何かが適切で、ちゃんとつれてゆけてる手応えがある</p>
    <p>ピックを飛ばし爪で弾き弦を切る場面。アンプの程よい鳴り</p>
    <p>なにかつかめた感じ。ただ、録音ボタンを押し忘れ記録録り損ね</p>
    <p>帰宅し、やむなく前日の「ポップミュージック」をお土産ディスクに。（最終日のお土産がポップミュージックてのはよいね）</p>
  </div>,
  "2016-10-29": <div>
    <p>最終日、会議室に通うのも今日まで（定期券を買ったのだった）早めに勝手に鍵を開け１９時ちょうどに開始。</p>
    <p>聴き手、林原聡太（今月７回目）河内伴理、くっちー（以上、てあしくちびる）、ジョニー大蔵大臣（水中、それは苦しい）、須川善行、たばやまたばお、野田茂則（キャロサンプ）、他六名。計十三名。</p>
    <h4>基本毎日vol.２３　曲目など</h4>
    <ol>
      <li>「<Link to="/title/14">いる場合／いない場合</Link>」</li>
      <li>「<Link to="/title/27">ティラノ</Link>」</li>
      <li>「天国」</li>
      <li>「<Link to="/title/7">かくもの／かかれるもの</Link>」</li>
      <li>「<Link to="/title/30">あるじゃないか</Link>」</li>
      <li>「<Link to="/title/8">いったことはないけど</Link>」</li>
      <li>「<Link to="/title/21">きもち</Link>」</li>
      <li>「<Link to="/title/28">GoGoGo／NoNoNo</Link>」</li>
      <li>「おみやげ」</li>
      <li>「<Link to="/title/20">このみち</Link>」</li>
      <p>ーーー（以下、勝手にアンコール）</p>
      <li>「センチメンタル」ゆめのようねいまのわたししあわせ（中略）ゆきお～ゆきお～ゆきお～（中略）５７歳♪</li>
      <li>「スターマン」（D・ボウイの歌を日本語にして）</li>
    </ol>
    <p>終了して元に戻して施錠（次の会議室使用者あり）</p>
    <p>最初から引っ張って行けるようになった実感。声としては疲れているのかもしれないが低いほうがよく出ている。２弦を切り、張り、今度は５弦を切り、張る。</p>
    <p>大きな拍手をいただき終了。元に戻して施錠。</p>
    <p>打ち上げ。話す。話し倒す。</p>
    <p>本日と前日、身体が前にのめって動き出している感覚。以前よりミスは減らせた。（歌詞を書きつけた紙を床にぶちまける方法はこれからも使えるだろうか？）</p>
    <p>つくりあげたものを直前に「より」のせること、生成された瞬間の加速度をふたたびつくること。</p>
  </div>,
  "2016-11-01": <div>
    <p>HPに記した文</p>
    <blockquote style={{backgroundColor: 'whitesmoke', fontSize: "small"}}>
      <p>「基本毎日」</p>
      <p>完遂いたしました</p>
      <p>観に、聴きに聴きにきて下さった一人ずつの、その一人ずつさ加減に、心から感謝です</p>
      <p>そのことが何よりも、たいへん力になりました</p>
      <p>この正体について、ぼくはこれからも考え続けます</p>
      <p>そしてこのこころみを気にとめて下さったひとりひとりにも</p>
      <p>実はとても感謝しております</p>
      <p>ありがとうございました</p>
      <br/>
      <p>今回の基本毎日</p>
      <p>ライブハウスに招かれず、誘われず、企画も持ち込まず、でちょうどぽっかり空いた時間があったのが、動機付けの一つではありました。</p>
      <p>常々、歌と演奏が行われる場所に、必ずしも飲食が伴わなくても良いんじゃないかな。とも考えていましたので、場所を探しました。</p>
      <p>自分の歌と演奏を聴いてもらえる場所を探す、ことは、同時に自分の歌と演奏のために最低限必要な要素を点検することでありました。そうすると、狭い場所ならボーカルマイクは要らない、エレキギターのアンプも小さなもので良い。照明も作らない。</p>
      <p>基本毎日同じ場所で続けるということは、毎日同じことは繰り返せない、ということであり、同時に</p>
      <p>一方、いつ出会うかわからない、初めて出会う方にも、どこをきちんとさせておかなければならないのか</p>
      <p>そのあたりを考え続けることにもなりました。</p>
      <p>自分の歌い演奏できることの内容を改め直すことにもなりました</p>
      <p>ぼくのような音楽家？演奏家？歌手？に求められていることは端正な演奏を無難に行うことではなく、毎回毎回その場に何かを初めて生成させるような試みを試みようとすることなのではないのか</p>
      <p>そんな風に気づきます。</p>
      <p>作り上げた曲を毎回その場で、より、なお、立ち上がるために、せねばならぬこと、また、そのやり方</p>
      <p>まだうまく説明できませんがその方法が確かにあるようには感じましたし</p>
      <p>どうやらぼくは常にそうしつづけてゆこうとしていることがわかりました</p>
      <p>そして</p>
      <p>それを説明するよりも</p>
      <p>まず次の機会にそういうことができるように</p>
      <p>してみようと念じます</p>
      <p>ただの小さな会議室であれができたのですから</p>
      <p>演奏や歌唱をするために作られた場所であるなら</p>
      <p>きっとなおさらどこでもできるはずであろうと考えます</p>
      <br/>
      <p>短期間に幾度も幾度もそこに向かえたことが生み出した変化がはっきり現れて来るのは</p>
      <p>これからのことだと思いますが</p>
    </blockquote>
  </div>,
  "2016-11-10": <div>
    <p>歌詞を書き出し。</p>
  </div>,
  "2016-11-15": <div>
    <p>最終日の「あるじゃないか」を焼く。</p>
  </div>,
  "2016-11-17": <div>
    <p>お土産CD−RWの塊の名前は「名前はまだない」だ。と閃く。山田正樹に歌詞カードのコンセプト伝えデザインを依頼。</p>
  </div>,
  "2016-11-18": <div>
    <p>紙袋に「佐藤」の印鑑で「さ」。を閃く。</p>
  </div>,
  "2016-11-21": <div>
    <p>焼き、スタンプ押して袋詰め、歌詞カード折り、輪ゴムかけ、外袋印鑑捺印、等々。一組作成に二時間弱かかる。</p>
  </div>,
  "2016-11-23": <div>
    <p>マンダラ２での佐藤幸雄とわたしたちライブ（wてあしくちびる）にて。</p>
    <p>一曲入りCD－RW２４枚組「名前はまだない」発売開始。</p>
  </div>,
}

export default diary
